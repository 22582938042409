import { Component,Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDatepicker, MatDialogRef} from '@angular/material';
import {DatabaseService} from '../../_services/DatabaseService';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogComponent} from '../../dialog/dialog.component';

@Component({
  selector: 'app-complaints-nature-problem',
  templateUrl: './complaints-nature-problem.component.html',
})
export class ComplaintsNatureProblemComponent implements OnInit {
  data: any = {};
  loading_list:any = false;
  mode:any;
  savingData = false;
  gift_id;
  complaint:any = {};
  date1:any;
  complaintfrom:any={};
  formData = new FormData();
  otpData:any;
  detailData:any={};
  detailfrom:any={};
  
  constructor(public db: DatabaseService, private route: ActivatedRoute, private router: Router,  public dialog: DialogComponent,
    @Inject(MAT_DIALOG_DATA) public model_data: any, public dialogRef: MatDialogRef<ComplaintsNatureProblemComponent>) {
      //console.log(model_data);
      this.date1 = new Date();
      
      this.data.id = model_data.id; 
      this.data.From = model_data.From; 
      this.data.mobile = model_data.mobile; 
      this.detailfrom.FromDetails = model_data.FromDetails; 
      this.detailfrom.Comstatus = model_data.comstatus; 


      if(this.detailfrom.FromDetails){
        this.detailData = model_data
        console.log(this.detailData )
      }


      this.data.status = model_data.status; 


      this.data.nature_problem = model_data.nature_problem; 
      if(this.data.From=='Solve'){
        this.getOtp(this.data.mobile)

      }
      
    }
    ngOnInit() {
      this.route.params.subscribe(params => {
        this.gift_id = params['gift_id'];
        this.complaint.nature_problem = this.data.nature_problem; 
//console.log( this.complaint );

      });
    }

    openDatePicker(picker : MatDatepicker<Date>)
    {
      picker.open();
    }
   
    addCompalintStatus(form:any)
    {
      this.savingData = true;
      this.complaint.created_by = this.db.datauser.id;

      this.db.post_rqst( { 'status' : this.complaint ,'id': this.data.id }, 'karigar/updateNatureProblem')
      .subscribe( d => {
        this.savingData = false;
        this.dialog.success( 'Status successfully Change');
        this.dialogRef.close(true);
        //console.log( d );
      });
    }

    savecomplaintfrom(form:any) {

      if(this.otpData!=this.complaintfrom.otp){
        this.dialog.error( 'OTP Invalid');
        return;
      }
      this.savingData = true;
      this.complaintfrom.created_by = this.db.datauser.id;
      this.complaintfrom.complain_id = this.data.id;
      this.complaintfrom.complaint_status = this.data.From;

      if(this.complaintfrom.complaint_status=='Pipe Line'){
        this.complaintfrom.courier_date = this.complaintfrom.courier_date  ? this.db.pickerFormat(this.complaintfrom.courier_date) : '';
      }
      if(this.complaintfrom.complaint_status=='Solve'){
        this.complaintfrom.solve_date = this.complaintfrom.solve_date  ? this.db.pickerFormat(this.complaintfrom.solve_date) : '';

      }
   
      this.complaintfrom.type = 'service'
      console.log(this.complaintfrom);
    
   
      this.db.post_rqst( {'data':this.complaintfrom}  , 'app_karigar/updateComplaintDetail')
      .subscribe( d => {

          this.dialog.success( 'Complaints Status Updated');
          this.savingData = false;
          this.dialogRef.close(true);

       
      });
    }

    onNoClick(): void{
    this.dialogRef.close(true);
    }

    getOtp(mobile){
      this.db.post_rqst( { 'id': this.data.id ,'mobile':mobile}, 'karigar/complaintSolveOtp')
      .subscribe( d => {
        this.otpData = d.otp;
        // this.dialog.success( 'Status successfully Change');
        // this.dialogRef.close(true);
        //console.log( d );
      });

    }


    numeric_Number(event: any) {
      const pattern = /[0-9\+\-\ ]/;
      let inputChar = String.fromCharCode(event.charCode);
      if (event.keyCode != 8 && !pattern.test(inputChar)) {
          event.preventDefault();
      }
  }

  updatecomplaintInfo(){
    this.detailData.complaints_id=this.data.id 

    this.db.post_rqst( {'data':this.detailData}, 'karigar/updateComplaintdetail')
    .subscribe( d => {

      this.dialog.success( 'Successfully Updated');
      this.savingData = false;
      this.dialogRef.close(true);
  
    });

  }
  }
  