import {Component,OnInit} from '@angular/core';
import {DatabaseService} from '../../_services/DatabaseService';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogComponent} from '../../dialog/dialog.component';
import {SessionStorage} from '../../_services/SessionService';

@Component({
  selector: 'app-edit-offer',
  templateUrl: './edit-offer.component.html',
})
export class EditOfferComponent implements OnInit {
  
  offer_id;
  loading_list = false;
  addOffer:any={};
  savingData = false;
  uploadUrl:any="";
  selected_image:any=[];
  
  constructor(public db: DatabaseService, private route: ActivatedRoute, private router: Router, public ses: SessionStorage,
    public dialog: DialogComponent) {
      this.uploadUrl = db.offer_imgurl;

    }
    
    ngOnInit() {
      this.route.params.subscribe(params => {
        this.offer_id =  this.db.crypto(params['offer_id'],false);
        if (this.offer_id) {
          console.log(this.offer_id );
          this.getOfferDetails();
        }
      });
    }
    getData:any = {};
    banner_multi_img:any = [];
    getOfferDetails() {
      this.loading_list = true;
      this.db.post_rqst(  {'offer_id':this.offer_id}, 'offer/offerDetail')
      .subscribe(d => {
        this.loading_list = false;
        //console.log(d);
        this.addOffer = d.offer;
        this.banner_multi_img = d.offer_banner;

      });
    }
    saveOffer(form:any) {
      this.savingData = true;
      this.addOffer.offer_banner=this.banner_multi_img;
      this.addOffer.start_date = this.addOffer.start_date  ? this.db.pickerFormat(this.addOffer.start_date) : '';
      this.addOffer.end_date = this.addOffer.end_date  ? this.db.pickerFormat(this.addOffer.end_date) : '';
      this.addOffer.created_by = this.db.datauser.id;
      this.db.post_rqst( { 'offer' : this.addOffer, 'offer_id' : this.offer_id ,'login_id' :this.db.datauser.id }, 'offer/offerUpdate')
      .subscribe( d => {
        this.savingData = false;
        //console.log( d );
        if(d['status'] == 'EXIST' ){
          this.dialog.error( 'Offer Code Already exists');
          return;
        }
        this.router.navigate(['offer-detail/'+ this.db.crypto(this.offer_id)]);
        this.dialog.success( 'Offer successfully Updated');
      });
    }
    onUploadChange1(evt: any) {
      const file = evt.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = this.handleReaderLoaded1.bind(this);
        reader.readAsBinaryString(file);
      }
    }
    handleReaderLoaded1(e) {
      this.addOffer.offer_banner = 'data:image/png;base64,' + btoa(e.target.result) ;
    }

    deleteProductImage(index)
    {
        this.banner_multi_img.splice(index,1)
    }



    fileName = '';
    offer_banner = new FormData();
    imgFlag :boolean = false;
    onUploadChangeOffer(data: any)
    {            
        console.log(data);
        
        for(let i=0;i<data.target.files.length;i++)
        {
            console.log(data.target.files[i]);
            let files = data.target.files[i];
            let filesName = data.target.files[i].name;
            console.log(filesName);
            
            if (files) 
            {
                this.fileName = data.target.files[i].name;
                console.log(filesName);
                let reader = new FileReader();

                reader.onload = (e: any) => {
                    console.log(e);
                    this.banner_multi_img.push({"offer_banner":e.target.result});
                    console.log(e.target.result);
                    this.imgFlag = true;
                    
                   
                }
                reader.readAsDataURL(files);
            }

            // let imageIndex;
            // if(this.selected_image.length==0){
            //      imageIndex=i;
            // }
            // else{
            //      imageIndex = this.selected_image.length;
            // }
            // this.image.append("image_"+imageIndex,data.target.files[i],data.target.files[i].name);
            this.offer_banner.append("offer_banner"+i,data.target.files[i],data.target.files[i].name);


            console.log(this.offer_banner);
            
        }
    }
  }
  